<template>
    <v-row>
        <v-col>
            <div :id="id" :style="{height: height+'px', 'z-index': 150}"></div>
        </v-col>
    </v-row>
</template>

<script>
import L from 'leaflet'
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/assets/css/leaflet.css'
export default {
    name: "SearchAddress",
    props: ['dataForMap', 'zoom', 'id', 'height'],
    data: function () {
        return {
            marker: {},
            map: {},
            latLng: {}
        }
    },
    async mounted() {
        this.latLng = L.latLng(this.dataForMap.lat, this.dataForMap.lng);
        this.map = new L.Map(this.id).setView(this.latLng, this.zoom);

        L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
            attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
            maxZoom: 18,
            id: 'mapbox/streets-v11',
            tileSize: 512,
            zoomOffset: -1,
            accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN
        }).addTo(this.map);

        this.marker = L.marker(this.latLng,{
            draggable: true,
            autoPan: true
        }).addTo(this.map)

        this.marker.on('dragend', this.dragendHandler)

        const provider = new OpenStreetMapProvider();

        const searchControl = new GeoSearchControl({
            notFoundMessage: 'Sorry, that address could not be found.',
            provider: provider,
            marker: this.marker,
            style: 'bar',
            autoComplete: true,
            retainZoomLevel: true,
            autoClose: true,
        })

        this.map.addControl(searchControl);

        this.map.on('moveend', () => {
            this.marker.setLatLng(this.latLng)
        })

        this.map.on('geosearch/showlocation', this.geosearchHandler)
    },
    methods: {
        geosearchHandler(result) {
            this.latLng.lat = result.location.y
            this.latLng.lng = result.location.x
            this.setDataForMap()
        },
        dragendHandler(data) {
            this.latLng.lat = data.target._latlng.lat
            this.latLng.lng = data.target._latlng.lng
            this.setDataForMap()
        },
        setDataForMap() {
            this.$emit('setDataForMap', this.latLng)
        }
    },
    watch: {
        dataForMap: {
            deep: true,
            handler: function () {
                this.marker.setLatLng(this.dataForMap)
                this.map.setView(this.dataForMap)
                this.$emit('setAddressForMap', this.dataForMap)
            }
        }
    }
}
</script>
