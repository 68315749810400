<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col class="text-left">
                        <v-btn text :to="{name: 'admin.projects.index'}">return</v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        {{ project.name_hy }}
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form ref="form">
                    <v-row>
                        <v-col>
                            <v-text-field type="text" label="Name in russian"
                                          placeholder="Please enter a name in russian" outlined
                                          v-model="project.name_ru"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field type="text" required :rules="nameRule" label="Name in english"
                                          placeholder="Please enter a name in english" outlined
                                          v-model="project.name_en"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field type="text" required :rules="nameRule" label="Name in armenian"
                                          placeholder="Please enter a name in armenian" outlined
                                          v-model="project.name_hy"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field type="text" label="Slug" placeholder="Please enter a slug (english only)"
                                          outlined v-model="project.slug"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea type="text" placeholder="Please enter detailed information in russian"
                                        label="Detailed information in russian"
                                        v-model="project.description_ru"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea type="text" placeholder="Please enter detailed information in english"
                                        label="Detailed information in english"
                                        v-model="project.description_en"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea type="text" placeholder="Please enter detailed information in armenian"
                                        label="Detailed information in armenian"
                                        v-model="project.description_hy"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea type="text" placeholder="Please enter short description in russian"
                                        label="Short description in russian"
                                        v-model="project.short_description_ru"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea type="text" placeholder="Please enter short description in english"
                                        label="Short description in english"
                                        v-model="project.short_description_en"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea type="text" placeholder="Please enter short description in armenian"
                                        label="Short description in armenian"
                                        v-model="project.short_description_hy"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                                v-model="project.city_id"
                                :items="cities"
                                item-text="name"
                                item-value="id"
                                outlined
                                label="Select a city"
                            >
                            </v-select>
                            <v-text-field type="number" min="0" label="City id"
                                          v-model="project.city_id"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                                v-model="project.developer_id"
                                :items="developers"
                                item-text="name"
                                item-value="id"
                                outlined
                                :rules="rules.select"
                                label="Select a developer"
                            >
                            </v-select>
                            <v-text-field type="number" min="0" label="Developer id"
                                          v-model="project.developer_id"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field type="number" min="0" label="District id"
                                          v-model="project.district_id"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-toolbar-title class="min-height27">{{ address }}</v-toolbar-title>
                        </v-col>
                    </v-row>
                    <search-address v-if="projectLoaded" class="mb-3"
                                    :height="400"
                                    :data-for-map="dataForMap"
                                    :zoom="17"
                                    id="search-address"
                                    @setDataForMap="setDataForMap"
                                    @setAddressForMap="fetchProjectAddress"
                    />
                    <v-row>
                        <v-col>
                            <v-text-field type="number" min="0" label="Latitude"
                                          v-model="dataForMap.lat" :rules="maxLat"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field type="number" min="0" label="Longitude"
                                          v-model="dataForMap.lng" :rules="maxLong"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field type="number" min="0" v-model="project.completed_at_year"
                                          label="Completed at year"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field type="number" min="0" label="Min floors"
                                          v-model="project.min_floors"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field  type="number" min="0" label="Max floors"
                                           v-model="project.max_floors"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field type="number" min="0" label="Completed at quarter"
                                          v-model="project.completed_at_quarter"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field type="number" min="0" label="Completed at month"
                                          v-model="project.completed_at_month"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea type="text" label="How to get to in russian"
                                        v-model="project.how_to_get_to_ru"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea type="text" label="How to get to in english"
                                        v-model="project.how_to_get_to_en"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea type="text" label="How to get to in armenian"
                                        v-model="project.how_to_get_to_hy"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea type="text" label="Infrastructure in russian"
                                        v-model="project.infrastructure_ru"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea type="text" label="Infrastructure in english"
                                        v-model="project.infrastructure_en"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea type="text" label="Infrastructure in armenian"
                                        v-model="project.infrastructure_hy"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea type="text" label="How to buy in russian"
                                        v-model="project.how_to_buy_ru"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea type="text" label="How to buy in english"
                                        v-model="project.how_to_buy_en"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea type="text" label="How to buy in armenian"
                                        v-model="project.how_to_buy_hy"></v-textarea>
                        </v-col>
                    </v-row>
                    <template v-for="lang in ['en', 'ru', 'hy']">
                        <template v-if="$_.isObject(project['characteristics_' + lang])">
                            <v-row v-for="(char, idx) in characteristics" :key="idx+lang">
                                <v-col>
                                    <v-text-field
                                        type="text"
                                        :label="$t('headers.' + char, 'ru' ) + '(' + lang + ')'"
                                        v-model="project['characteristics_' + lang][char]"/>
                                </v-col>
                            </v-row>
                        </template>
                    </template>
                    <v-row>
                        <v-col class="d-flex justify-center align-center flex-column">
                            <div class="min-width100">
                                <avatar-edit min-width="100%"
                                             :current_url="project.image_url"
                                             @input="handleSaveProject"
                                             v-model="project.image_url"
                                ></avatar-edit>
                            </div>
                            <div class="min-width100">
                                <v-text-field label="Main image" v-model="project.image_url" disabled></v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row v-for="(projectPhotoCategory, index) in projectPhotoCategories" :key="index">
                        <v-col class="d-flex justify-center align-center flex-column">
                            <div class="min-width100">
                                <v-item-group>
                                    <v-row>
                                        <v-col
                                            v-for="(photo, photoIndex) in photos[projectPhotoCategory.id]"
                                            :key="index + photoIndex"
                                            class="d-flex child-flex"
                                            cols="4"
                                        >
                                            <v-img
                                                :src="photo"
                                                height="150"
                                                max-width="200"
                                                class="text-right pa-2"
                                                @click.prevent="deletePhoto(projectPhotoCategory.id, photo)"
                                                alt="">
                                                <v-btn icon dark>
                                                    <v-icon>
                                                        {{ 'mdi-close-box' }}
                                                    </v-icon>
                                                </v-btn>
                                            </v-img>
                                        </v-col>
                                        <avatar-edit
                                            :ref="'photoUploader' + projectPhotoCategory.id"
                                            v-model="photos[projectPhotoCategory.id][photos[projectPhotoCategory.id].length]"
                                            min-width="100%"
                                            @input="refreshAvatarEdit(projectPhotoCategory.id)"
                                        />
                                    </v-row>
                                </v-item-group>

                            </div>
                            <div class="min-width100">
                                <v-text-field :label="projectPhotoCategory.name" disabled></v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row v-if="project.created_at">
                        <v-col>
                            <v-text-field label="Created at" readonly disabled v-model="formatDate"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn block color="green" @click="handleSaveProject">Save Changes</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="snackbar" color="green" :timeout="timeout_snackbar">{{ text_snackbar }}</v-snackbar>
    </admin-dashboard-layout>
</template>

<script>
import Project from "../../../models/Project";
import City from "../../../models/City";
import Developer from "../../../models/Developer";
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import AvatarEdit from "../../../components/AvatarEdit";
import SearchAddress from "@/components/SearchAddress";
import axios from "axios";
import i18n from "@/i18n";
import ProjectPhotoCategory from "@/models/ProjectPhotoCategory";

export default {
    name: "projects-show",
    components: {AdminDashboardLayout, AvatarEdit, SearchAddress},
    data: function () {
        return {
            formatDate: 0,
            flagSaveUpdate: false,
            project: {
                photos: [],
                characteristics_en: {},
                characteristics_ru: {},
                characteristics_hy: {},
            },
            cities: [],
            developers: [],
            valid: false,
            rules: {
                select: [(v) => !!v || "Item is required"],
            },
            nameRule: [v => !!v || 'Name is required'],
            maxLat: [v => v < 91 || 'Latitude should not exceed 90 integers.'],
            maxLong: [v => v < 181 || 'Longitude must not exceed 180 integers.'],
            snackbar: false,
            text_snackbar: 'Saved successfully',
            timeout_snackbar: 2000,
            defaultLatitude: 0,
            defaultLongitude: 0,
            projectLoaded: false,
            address: 'No address',
            dataForMap: {
                lat: 40.1776245,
                lng: 44.5126174
            },
            projectPhotoCategories: [],
            photos: {},
            characteristics: [
                "deadline",
                "building_type",
                "number_of_storeys",
                "ceiling_height",
                "parking",
                "new_building_class",
                "rating",
                "stage_of_construction",
            ]
        }
    },
    computed: {},
    methods: {
        async handleSaveProject() {
            this.formValid = this.$refs.form.validate()
            if (this.formValid) {
                this.project.latitude = this.dataForMap.lat
                this.project.longitude = this.dataForMap.lng
                const project = await new Project({...this.project, ...{photos: this.photos}});

                this.project = this.flagSaveUpdate
                    ? await project.patch()
                    : await project.save()

                await this.$router.push({name: 'admin.projects.show', params: {id: this.project.id}}).catch(()=>{})

                this.snackbar = true
            }
        },
        returnFormDate(uc) {
            this.formatDate = new Date(uc).toLocaleString()
        },

        fetchProjectAddress() {
            return axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${this.dataForMap.lat}&lon=${this.dataForMap.lng}&accept-language=${i18n.locale}`, {
                withCredentials: false
            })
                .then(response => {
                    this.address = response.data.display_name
                })
                .catch(() => {
                    this.address = "Can't find this address"
                })
        },
        setDataForMap(latLng) {
            this.dataForMap.lat = latLng.lat
            this.dataForMap.lng = latLng.lng
            this.fetchProjectAddress()
        },
        deletePhoto(categoryId, photo) {
            this.photos[categoryId] = this.photos[categoryId].filter(current => current !== photo)
        },
        refreshAvatarEdit(categoryId) {
            this.$refs['photoUploader' + categoryId][0].$refs.myVueDropzone.removeAllFiles();
        }
    },

    async mounted() {
        const projectId = this.$route.params.id

        if (projectId) {
            this.project = await Project.find(projectId)
            this.flagSaveUpdate = true
            this.returnFormDate(this.project.created_at)
            this.dataForMap.lat = this.project.latitude
            this.dataForMap.lng = this.project.longitude
            await this.fetchProjectAddress()
        }

        ['hy', 'en', 'ru'].forEach(lang => {
            const field = 'characteristics_' + lang;

            if (Array.isArray(this.project[field])) {
                this.$set(this.project, 'characteristics_' + lang, {})
            }

            if (!(field in this.project)) {
                this.$set(this.project, 'characteristics_' + lang, {})
            }
        })

        this.projectLoaded = true
        this.cities = await City.get();
        this.developers = await Developer.get();
        this.projectPhotoCategories = await ProjectPhotoCategory.get()

        this.projectPhotoCategories.forEach((category) => {
            this.$set(this.photos, category.id, []);
            this.photos[category.id] = this.project.photos
                .filter(photo => photo.photo_category_id === category.id)
                .filter(photo => !!photo.image_url)
                .map(photo => photo.image_url);
        });
    },

}
</script>

<style lang="scss" scoped>
.min-width100 {
    min-width: 100%;
}

.max-width250 {
    max-width: 250px;
}
.min-height27 {
    min-height: 27px;
}
</style>
